<template>
  <div id="app">
    <!-- <el-scrollbar height="699px" @scroll="scroll"> -->
     <router-view v-if="isRouterAlive"></router-view>
    <!-- </el-scrollbar> -->
  </div>
</template>
<script>
import Cookie from 'js-cookie'
export default {
  provide(){
    return{
      reload:this.reload
    };
  },
  data(){
    return{
      isRouterAlive:true,
      beforeUnloadTime:0,
      unloadTime:0,
    }
  },
  mounted() {
      window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
      window.addEventListener('unload', e => this.unloadHandler(e))
  },
  beforeUnmount(){

  },
  beforeDestroy(){
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  destroyed() {
      window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
      window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  methods:{
    beforeunloadHandler(e){
      this.beforeUnloadTime=new Date().getTime();
      //Cookie.set('beforeunloadHandler',new Date().getTime())
    },
    unloadHandler(e){
      this.unloadTime=new Date().getTime()-this.beforeUnloadTime;
      localStorage.setItem('beforeUnloadTime',this.beforeUnloadTime)
      localStorage.setItem('unloadTime',this.unloadTime)
      //判断是窗口关闭还是刷新
      if(this.unloadTime<=5){
          Object.keys(Cookie.get()).forEach(cookieName =>{
              console.log(cookieName)
              Cookie.remove(cookieName);
          });
        localStorage.setItem('data','退出存的数据'+new Date().getTime());
      }else{
        this.unloadTime = 0;
        this.beforeUnloadTime = 0
        localStorage.setItem('fresh','刷新存的数据'+new Date().getTime());
      }
    },
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function(){
        this.isRouterAlive = true;
      })
    },
    // scroll(e){
    //   console.log(e)
    // }
  }
}
</script>
<style lang="scss">
html{
  height: 100%;
  overflow:auto;
}
body{
  height: 100%;
  margin: 0;
  font-size:1rem;
  overflow:auto;
  // font-family:"Mazzard M Medium"
}
#app {
  height: 100%;
}
img{
  max-width: 100%;
}
p{
  margin: 0 0 8px 0;
}
input{
  outline:none;
}
// .scrollbar-demo-item {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 50px;
//   margin: 10px;
//   text-align: center;
//   border-radius: 4px;
//   background: #8f3ca0;
//   color: #8f3ca0;
// }
.el-scrollbar__bar.is-vertical{
    z-index:99;
    display:block !important;
}
.imginner .el-scrollbar__bar.is-vertical{
  background-color: #545b61 !important;
}
.el-scrollbar__thumb{
    // background-color: #8f3ca0 !important;
    background-color: #E81F76 !important;
    opacity: 1 !important;
}
</style>
