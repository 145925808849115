import "core-js/modules/es.array.push.js";
import { removeAllCookie, getCookie, setCookie } from "@/utils/cookie";
import { createRouter, createWebHashHistory } from "vue-router";
import Api from "@/api/booster_api";
const MobileHome = () => import("@/pages/mobile/index.vue");
const Login = () => import("@/pages/login/index.vue");
const Restore = () => import("@/pages/restore/index.vue");
const RestoreEmail = () => import("@/pages/restore/email.vue");
const Registration = () => import("@/pages/registration/index.vue");
const layoutUser = () => import("@/components/user/layout");
const Home = () => import("@/pages/user/home/index.vue");
const Job = () => import("@/pages/user/job/index.vue");
const MyProfile = () => import("@/pages/user/profile/index.vue");
const edit = () => import("@/pages/user/profile/edit.vue");
const chat = () => import("@/pages/user/profile/chat.vue");
const Leaderboard = () => import("@/pages/user/leaderboard/index.vue");
const Review = () => import("@/pages/user/review/index.vue");
const BoosterProfile = () => import("@/pages/user/profile/booster.vue");
const Tutorial = () => import("@/pages/user/home/tutorial.vue");
const Privacy = () => import("@/pages/user/home/privacy.vue");
const Terms = () => import("@/pages/user/home/terms.vue");
const Contact = () => import("@/pages/user/home/contact.vue");
const Faq = () => import("@/pages/user/home/faq.vue");
const BlogList = () => import("@/pages/user/blog/list.vue");
const BlogDetail = () => import("@/pages/user/blog/detail.vue");
const Aboutus = () => import("@/pages/user/home/about.vue");
const AllChat = () => import("@/pages/user/chat/index.vue");
const NewChat = () => import("@/pages/user/chat/new.vue");
const layoutBooster = () => import("@/components/booster/layout");
const order = () => import("@/pages/booster/order/index.vue");
const profile = () => import("@/pages/booster/profile/index.vue");
const boosterProfile = () => import("@/pages/booster/profile/boosterProfile.vue");
const boosterChat = () => import("@/pages/booster/chat/index.vue");
const boosterBalance = () => import("@/pages/booster/balance/index.vue");
const support = () => import("@/pages/booster/support/support.vue");
const supportChat = () => import("@/pages/booster/support/supportChat.vue");
const statistic = () => import("@/pages/booster/statistic/index.vue");
const fine = () => import("@/pages/booster/fine/index.vue");
const rule = () => import("@/pages/booster/rule/index.vue");
const announcement_dashboard = () => import("@/pages/booster/news/index.vue");
const leaderboard = () => import("@/pages/booster/leaderboard/index.vue");
const routerHistory = createWebHashHistory();
const router = createRouter({
  history: routerHistory,
  scrollBehavior(to, from, savedPosition) {
    console.log(to);
    console.log(from);
    console.log(savedPosition);
    const scroll = {};
    scroll.top = 0;
    return scroll;
  },
  routes: [{
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      keepAlive: false
    }
  }, {
    path: "/registration",
    name: "Registration",
    component: Registration,
    meta: {
      keepAlive: false
    }
  }, {
    path: "/restore",
    name: "Restore",
    component: Restore,
    meta: {
      keepAlive: false
    }
  }, {
    path: "/restoreemail",
    name: "RestoreEmail",
    component: RestoreEmail,
    meta: {
      keepAlive: false
    }
  }, {
    path: "/mobilehome",
    name: "MobileHome",
    component: MobileHome,
    meta: {
      keepAlive: false
    }
  }, {
    path: "/",
    component: layoutUser,
    meta: {
      keepAlive: false
    },
    children: [{
      path: '',
      redirect: '/user/home'
    }, {
      name: 'user/home',
      path: '/user/home',
      component: Home
    }, {
      name: 'user/job',
      path: '/user/job',
      component: Job
    }, {
      name: 'user/leaderboard',
      path: '/user/leaderboard',
      component: Leaderboard
    }, {
      name: 'user/review',
      path: '/user/review',
      component: Review
    }, {
      name: 'user/myProfile',
      path: '/user/myProfile',
      component: MyProfile
    }, {
      name: 'user/edit',
      path: '/user/edit',
      component: edit
    }, {
      name: 'user/chat',
      path: '/user/chat',
      component: chat
    }, {
      name: 'user/boosterProfile',
      path: '/user/boosterProfile',
      component: BoosterProfile
    }, {
      name: 'user/tutorial',
      path: '/user/tutorial',
      component: Tutorial
    }, {
      name: 'user/privacy',
      path: '/user/privacy',
      component: Privacy
    }, {
      name: 'user/terms',
      path: '/user/terms',
      component: Terms
    }, {
      name: 'user/contact',
      path: '/user/contact',
      component: Contact
    }, {
      name: 'user/question',
      path: '/user/question',
      component: Faq
    }, {
      name: 'user/bloglist',
      path: '/user/bloglist',
      component: BlogList
    }, {
      name: 'user/blogdetail',
      path: '/user/blogdetail',
      component: BlogDetail
    }, {
      name: 'user/aboutus',
      path: '/user/aboutus',
      component: Aboutus
    }, {
      name: 'user/allchat',
      path: '/user/allchat',
      component: AllChat
    }, {
      name: 'user/newchat',
      path: '/user/newchat',
      component: NewChat
    }]
  }, {
    path: "/",
    component: layoutBooster,
    meta: {
      keepAlive: false
    },
    children: [{
      path: '',
      redirect: '/booster/orders'
    }, {
      name: 'booster/orders',
      path: '/booster/orders',
      component: order,
      meta: {
        keepAlive: false
      }
    }, {
      name: 'booster/profile',
      path: '/booster/profile',
      component: profile
    }, {
      name: 'booster/booster_profile',
      path: '/booster/booster_profile',
      component: boosterProfile
    }, {
      name: 'booster/chat',
      path: '/booster/chat',
      component: boosterChat
    }, {
      name: 'booster/balance',
      path: '/booster/balance',
      component: boosterBalance
    }, {
      name: 'booster/support',
      path: '/booster/support',
      component: support
    }, {
      name: 'booster/support_chat',
      path: '/booster/supportChat',
      component: supportChat
    }, {
      name: 'booster/statistic',
      path: '/booster/statistic',
      component: statistic
    }, {
      name: 'booster/goboost_fine',
      path: '/booster/goboost_fine',
      component: fine
    }, {
      name: 'booster/goboost_rules',
      path: '/booster/goboost_rules',
      component: rule
    }, {
      name: 'booster/announcement_dashboard',
      path: '/booster/announcement_dashboard',
      component: announcement_dashboard
    }, {
      name: 'booster/leaderboard',
      path: '/booster/leaderboard',
      component: leaderboard
    }]
  }]
});
const authMe = function () {
  Api.authMe().then(res => {
    if (res.code == 200) {
      localStorage.setItem('unread', res.data.unread);
    } else {
      ElMessage.error(res.message);
    }
  }).catch(err => {
    ElMessage.error(err.message);
  });
};
router.beforeEach((to, from, next) => {
  const nowTime = Math.round(new Date() / 1000);
  const timer = getCookie('expires_in');
  // console.log('token===' + getCookie('token') )
  // console.log('timer===' + timer )
  // console.log('nowTime===' + nowTime )
  // console.log('role===' + getCookie('role') )
  // if(getCookie('token') && timer > nowTime){
  //   if(to.path == '/login'){
  //       if(getCookie('role') == 'booster'){
  //         router.push('/booster/home');
  //       }else{
  //         router.push('/user/home');
  //       }
  //   }
  //     // if(getCookie('role') == 'booster'){
  //     //   router.push('/booster/home');
  //     // }else{
  //     //   router.push('/user/home');
  //     // }
  // }
  if (to.path == '/login') {
    if (getCookie('role') == 'booster') {
      router.push('/booster/orders');
    } else if (getCookie('token') && getCookie('role') == 'user') {
      router.push('/user/home');
    }
  }
  if (from.path == '/booster/supportChat' || from.path == "/booster/chat") {
    if (getCookie('token')) {
      authMe();
    }
    next();
  }
  console.log('noteme==========' + to.name.split('/')[0]);
  if (to.name.split('/')[0] == 'user') {
    if (getCookie('role') == 'booster' && getCookie('token')) {
      const nowTime = Math.round(new Date() / 1000);
      const timer = getCookie('expires_in');
      console.log('nowTime===' + nowTime);
      if (timer < nowTime) {
        console.log("booster token expires");
        router.push('/user/home');
        removeAllCookie();
      } else {
        router.push('/booster/orders');
      }
    }
  }
  if (to.path == '/mobilehome' || to.path == '/login' || to.path == '/registration' || to.path == '/restore' || to.path == '/restoreemail') {
    next();
  } else {
    const nowTime = Math.round(new Date() / 1000);
    const timer = getCookie('expires_in');
    if (getCookie('role') == 'booster') {
      if (!getCookie('token') || timer < nowTime) {
        console.log("booster token expires");
        router.push('/login');
        removeAllCookie();
        return;
      }
    }
    if (!getCookie('role')) {
      setCookie('role', 'user');
    }
    if (to.redirectedFrom) {
      if (getCookie('role') == 'booster') {
        router.push('/booster/orders');
      } else {
        router.push('/user/home');
      }
    } else {
      // if (to.name.split('/')[0] == "user") {
      //   next();
      // } else if (to.name.split('/')[0] == getCookie('role')) {
      //   next();
      // }
      next();
    }
  }
});
export default router;